import React from 'react';
import Data from '../Data.json';

const Education = () => {
    return (
      <>
        {Data.education.map((edu) => (
          <div key={edu.id} className="mt-5 flex flex-col">
            <p className="font-bold text-black">{edu.institution}</p>
            <p className="">{edu.qualification}{edu.grade && `, ${edu.grade}`}</p>
          </div>
        ))}
      </>
    )
}

export default Education;