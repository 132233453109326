import React from 'react';
import Data from '../Data.json';


const BasicInfo = () => {
  return (
    <>
      <h1 className="text-4xl sm:text-5xl font-bold mt-10 sm:mt-3 mb-3 text-black" id="text">{Data.basic_info.name}</h1>
      <h2 className="text-lg sm:text-xl mt-2 mb-4 text-black">{Data.basic_info.byline}</h2>
      <p className="">{Data.basic_info.bio}</p>
    </>
  )
}

export default BasicInfo;